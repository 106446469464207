const serverapiUrl='http://8.138.117.36:8080'
// const serverapiUrl='http://114.55.2.142:8089/'


function getServerapiUrl () {
  return serverapiUrl
}
module.exports = {
	getServerapiUrl
}
